<template>
    <div class="modal fade" :id="modalId" tabindex="-1" :aria-labelledby="modalId + 'Label'" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Grupe alata</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>

                <div class="modal-body">
                    
                    <div v-for="(tooltype, i) in oldTypes" :key="i" class="input-group">
                        <input :class="{'text-decoration-line-through': tooltype.deleted, 'fw-bold': tooltype.edited}" v-model="tooltype.name" @blur="changedType(tooltype)" type="text" class="form-control" aria-label="Dollar amount (with dot and two decimal places)">
                        <button class="btn btn-outline-secondary" type="button" @click="deleteToggle(tooltype)" ><font-awesome-icon icon="trash" /></button>
                    </div>

                    <div v-for="(tooltype, i) in newTypes" :key="'new-' + i" class="input-group">
                        <input v-model="tooltype.name" type="text" class="form-control fw-bold" aria-label="Dollar amount (with dot and two decimal places)">
                        <button class="btn btn-outline-secondary" type="button" @click="deleteNewType(i)" ><font-awesome-icon icon="trash" /></button>
                    </div>

                    <div class="d-grid">
                        <button class="btn btn-light mt-1 text-center" @click="addTypeRow"><font-awesome-icon icon="plus-circle" size="2x" /></button>
                    </div>

                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"> Zatvori </button>
                    <button @click="saveTypes()" type="button" class="btn btn-primary"> Sačuvaj </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import ToolsService from '@/service/ToolsService.js';
    import UtilService from '@/service/UtilService.js';

    export default {

        props: {
            modalId: String,
            toolTypes: Array,
            thisModal: Object
        },

        components: {
        },
        
        data() {
            return {
                oldTypes: [],
                newTypes: [],

                saved_status: false,
                updated_status: false,
            }
        },

        computed: {

        },

        methods: {

            userFullName : UtilService.userFullName,

            validate() {
                let form = document.getElementById("tool-damage-form");
                form.classList.add('was-validated');

                if (!form.checkValidity())
                    return false;

                return true;
            },

            deleteToggle(toolType) {
                if (toolType.deleted) toolType.deleted = false;
                else toolType.deleted = true;

                this.$set(toolType, 'edited', true);
            },

            deleteNewType(newTypeIndex) {
                this.newTypes.splice(newTypeIndex, 1);
            },

            addTypeRow() {
                let toolType = {
                    name: "",
                    edited: true
                }

                this.newTypes.push(toolType)
            },

            changedType(toolType){
                // console.log(toolType);
                this.$set(toolType, 'edited', true);
            },

            saveTypes() {
                this.saveEditedTypes();
                this.uploadNewTypes();
            },

            saveEditedTypes() {
                let data = this.oldTypes.filter(x => x.edited != null);
                data.forEach(element => {
                    ToolsService.putToolType(element).then(result => {
                        console.log(result);
                        this.saved_status = true;
                        if (this.updated_status) this.closeModal();

                    }).catch(error => {
                        console.log(error);
                        alert("Neuspesno cuvanje grupe alata")
                    }) 
                });
            },

            uploadNewTypes() {
                let data = this.newTypes.filter(x => x.name != "");
                data.forEach(element => {
                    ToolsService.addToolType(element).then(result => {
                        console.log(result);
                        this.updated_status = true;
                        if (this.saved_status) this.closeModal();
                        
                    }).catch(error => {
                        console.log(error);
                        alert("Neuspesno cuvanje grupe alata")
                    }) 
                });
            },

            closeModal() {
                this.thisModal?.hide();
            },

            initData() {

                this.oldTypes = UtilService.deepClone(this.toolTypes);
                this.newTypes = []
            },

            setUpListener() {
                let myModalEl = document.getElementById(this.modalId)
                myModalEl.addEventListener('shown.bs.modal', () => {
                    this.initData();
                })
            },

        },

        mounted() {
            this.setUpListener();
        },

        created() {
        }

    }
</script>

<style scoped>
.v-select.form-control{
    height: 4.5em;
}

.tool-image-top {
    transition: all 0.25s ease;
    cursor: zoom-in;
    max-width: 100px;
    max-height: 500px;
    border-radius: 100%;
}

.arrow-down-cricle {
    padding: 15px 21px 12px;
    display: inline-block;
    border-radius: 100%;
}

.arrow-down-cricle-success {
    border: 1px solid green;
    background: #8cff00;
}

.text-area-floating {
    height: 8em;
}

input[type=checkbox] {
  display: none;
}

input[type=checkbox]:checked ~ label > .tool-image-top {
    cursor: zoom-out;
    max-width: 100%;
    border-radius: 0;

}


.tool-image-damage {
    max-width: 100px;
    max-height: 500px;
    border-radius: 100%;

    transition: all 0.25s ease;
    cursor: zoom-in;
}

input[type=checkbox]:checked ~ label > .tool-image-damage {
    cursor: zoom-out;
    max-width: 400px;
    border-radius: 0;

}
</style>
