<template>
    <div class="modal fade" :id="modalId" tabindex="-1" :aria-labelledby="modalId + 'Label'" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">
                Skeniraj QR ili Bar kod
              </h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">

            <template v-if="scanning">
                <span v-if="!loaded">Ucitava se</span>
                <StreamBarcodeReader ref="toolqrScanner" @decode="onDecode" @loaded="onLoaded" @error="onError" />                                    
            </template>
            <template v-else>
                <button class="btn btn-primary" @click="openCamera()">
                    <font-awesome-icon size="5x" icon="qrcode" />
                </button>
            </template>

            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" @click="closeModal()">Zatvori</button>
            </div>
          </div>
        </div>
    </div>
</template>

<script>

    import { StreamBarcodeReader } from "vue-barcode-reader"
    import { Modal } from 'bootstrap'
    import ToolsService from '@/service/ToolsService.js';


    export default {

        components: {
            StreamBarcodeReader
        },
        
        props: {
            modalId: {
                type: String,
                default: "",
            }
        },

        data() {
            return {
                modal: undefined,
                scanning: false,
                loaded: false,
                tool: null,
            }
        },

        methods: {
            onDecode(data) {
                if (data.startsWith("TOOLID|")) {
                    let id = data.split("|")[1]
                    this.redirectToId(id)

                }

                this.getToolByCode(data);
            },

            onError(error) {
                console.log(error);
            },

            onLoaded() {
                this.loaded = true;
                console.log(this.$refs.toolqrScanner);
            },

            openCamera() {
                this.scanning = true;
            },

            closeModal() {  
                let modalElement = document.getElementById(this.modalId);
                this.modal = Modal.getInstance(modalElement);
                
                this.modal.hide();
                this.stopCamera();

                this.scanning = false;
            },

            stopCamera() {
                this.scanning = false;
                this.$refs.toolqrScanner.codeReader.stream.getTracks().forEach(function (track) { track.stop() })
            },

            getToolByCode(code) {
                let data = {code: code}
                ToolsService.getToolByCode(data).then(result => {
                    this.stopCamera();
                    this.tool = result.data;
                    this.redirectToId(this.tool.id)

                }).catch(() => {
                    alert("Ne postoji alat sa tim kodom")
                })
            },

            redirectToId(id) {
                this.$router.push({ name: 'tool-profile', params:{'toolId': id}} )
            },

            setUpListener() {
                let myModalEl = document.getElementById(this.modalId)
                myModalEl.addEventListener('shown.bs.modal', () => {
                    this.scanning = true;
                })

                myModalEl.addEventListener('hidden.bs.modal', () => {
                    this.stopCamera();
                })
            },
        },

        mounted() {
            let modalElement = document.getElementById(this.modalId);
            this.modal = Modal.getInstance(modalElement);
            this.setUpListener();
        }

    }
</script>

<style>

</style>