<template>
    <div>
        <!-- <modal-add-edit-warehouse :thisModal="modalNewWarehouse" modalId="modalNewWarehouse" title="Novo skladište" @confirmed="confirmed" /> -->
        <modal-edit-warehouses :thisModal="modalEditWarehouses" :warehouses="warehouses" modalId="modalEditWarehouses" @confirmed="confirmed"  />

        <div class="m-0 d-flex flex-wrap" role="group" aria-label="Basic radio toggle button group">
            <div class="type-select">
                <input v-model="selectedWarehouseComputed" :value="undefined" type="radio" class="btn-check" name="warehouse-all" id="warehouse-all" autocomplete="off" checked @change="changedToolType">
                <label class="btn btn-outline-primary btn-sm type-select-lable" for="warehouse-all">Sve lokacije</label>
            </div>

            <template v-for="warehouse in warehouses">
                <div class="type-select" :key="warehouse.id">
                    <input v-model="selectedWarehouseComputed" :value="warehouse" type="radio" class="btn-check" :name="'warehouse-' + warehouse.id" :id="'warehouse-' + warehouse.id" autocomplete="off" @change="changedToolType">
                    <label class="btn btn-outline-primary btn-sm type-select-lable" :for="'warehouse-' + warehouse.id">{{warehouse.name}}</label>
                </div>
            </template>


            <div v-if="isAdmin" class="justify-content-end type-select">
                <button v-if="warehouses" class="btn btn-outline-primary btn-sm type-select-lable" @click="openEditModal()"><font-awesome-icon icon="pen" /></button>
                <button v-else class="btn btn-outline-primary btn-sm type-select-lable" @click="openEditModal()"><font-awesome-icon icon="plus" />Dodaj makacine</button>
            </div>

        </div>
    </div>

</template>

<script>
    import {mapState} from 'vuex'
    // import ModalAddEditWarehouse from '@/components/modals/tools/ModalAddEditWarehouse.vue';
    import { Modal } from 'bootstrap';
    import ModalEditWarehouses from '@/components/modals/tools/ModalEditWarehouses.vue';

    export default {
        components: { ModalEditWarehouses },
        
        props: {
            selectedWarehouse: Object,
        },

        data() {
            return {
                modalNewWarehouse: null,
                modalEditWarehouses: null,
            }
        },

        computed: {
            ...mapState([
                'warehouses',
                'user'
            ]),

            selectedWarehouseComputed: {
                get () {return this.selectedWarehouse},
                set (value) { this.$emit('update:selectedWarehouse', value)}
            },

            isAdmin() {
                if (this.user.role == "ADMIN") return true;
                return false;
            }

        },

        methods: {
            changedToolType() {
                this.$emit('changed');
            },

            openModal() {
                console.log(Modal);
                console.log("novi");
                this.modalNewWarehouse.show();
            },

            openEditModal() {
                this.modalEditWarehouses.show();
            },

            confirmed() {
                this.$store.dispatch('getAllWarehouses')
            }
        },


        mounted() {
            this.modalNewWarehouse = new Modal(document.getElementById('modalNewWarehouse'), {backdrop: 'static', keyboard: false});
            this.modalEditWarehouses = new Modal(document.getElementById('modalEditWarehouses'), {backdrop: 'static', keyboard: false});
        },

    }
</script>

<style>
    .type-select{
        padding: 0;
    }
    .type-select-lable {
        width: 100%;
        padding: 1em;
    }
</style>