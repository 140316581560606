<template>
    <div class="">
        <modal-confirm @confirm="deleteSelectedTool" inputId="confirmModal" title="Potvrda brisanja alata" message="Da li ste sigurni da želite da obrišete alat?"/>
        <modal-add-edit-tool :thisModal="modalNewTool" modalId="modalNewTool" title="Novi alat" :editTool="editTool" @confirmed="confirmedTool" />
        <modal-tool-transfer :thisModal="modalNewToolTransfer" modalId="modalNewToolTransfer" title="Transfer" :editTool="editTool" @confirmed="confirmedToolTransfer" />

        <modal-tool-q-r-scanner modalId="modalQR" />



        <!-- TOOOLS -->

        <h2 v-if="isManager">Alati</h2>
        <h2 v-else>Moji Alati</h2>

        <tool-types-buttons class="mb-3" :selectedToolType.sync="toolType" @changed="changedToolType()" />
        <tools-warehouse-buttons v-if="isManager" class="mb-3" :selectedWarehouse.sync="warehouse" @changed="changedWarehouse()" />
        <div>
            <ejs-grid
                ref="allTools"
                :dataSource="allTools" 
                class="all-tools-grid"
                :allowFiltering='true'
                :allowSorting='true'
                :showColumnMenu='true'
                :filterSettings='filterSettings'
                :allowPaging='true' 
                :pageSettings='pageSettings'
                :recordDoubleClick="toolDBClick"
                :toolbarClick='clickHandler'
                :toolbar='toolbar'
                :allowResizing='true'
                :allowTextWrap='true'

                >
                <e-columns>
                    <e-column type='checkbox' :allowFiltering='false' :allowSorting='false' width='60'></e-column>

                    <e-column field='id' headerText='ID' width='120' textAlign='Right' :isPrimaryKey='true' :visible='false' ></e-column>

                    
                    <e-column field='image_url' :allowFiltering='false' class="image-column" :allowSorting='false' :showColumnMenu='false' headerText='Slika' width='120' textAlign='Center' :template="toolsImage"></e-column>
                    <e-column field='name' headerText='Naziv' width='120' textAlign='Left' :template="toolsNameTemplate"></e-column>
                    <e-column field='identification' headerText='Identifikacija' width='120' textAlign='Left'></e-column>
                    <e-column field='manufacturer' headerText='Proizvodjač' width='120' textAlign='Left'></e-column>
                    <e-column field='type_obj.name' headerText='Tip' width='120' textAlign='Left'></e-column>
                    <e-column field='value' headerText='Vrednost' width='120' textAlign='Right'></e-column>

                    <e-column field='holder_display' headerText='Lokacija' width='120' textAlign='Left'></e-column>

                    <e-column field='approved_by.username' :valueAccessor='fullnameAccessor' headerText='Obradio' width='130' :visible='false'></e-column>

                    <e-column field='updated_at' :valueAccessor='dateTimeAccessor' headerText='Poslednja izmena' width='200' format="yMd HH:mm" textAlign='Right' :visible='true'></e-column>
                    <e-column field='modified_by.username' :valueAccessor='fullnameAccessor' headerText='Poslednji izmenio' width='130' :visible='false'></e-column>
                    <e-column field='created_at' :valueAccessor='dateTimeAccessor' headerText='Kreirano' width='200' format="yMd HH:mm" textAlign='Right' :visible='false'></e-column>
                    <e-column field='created_by.username' :valueAccessor='fullnameAccessor' headerText='Kreirao' width='130' :visible='false'></e-column>

                    <e-column headerText='' :visible='true' textAlign='Right' :template="toolsCMD"></e-column>

                </e-columns>
            </ejs-grid>    
        </div>
    </div>
    
</template>


<script>

    import {mapState} from 'vuex'
    // import RequestStatus from '@/components/RequestStatus.vue';
    // import { Modal } from 'bootstrap';
    import UtilService from '@/service/UtilService.js';

    import ToolsService from '@/service/ToolsService.js';
    import { Sort, Page, ColumnMenu, Filter, Group, Toolbar, Edit, Resize } from "@syncfusion/ej2-vue-grids";
    import ToolTypesButtons from '@/components/tools/ToolTypesButtons.vue';
    import ModalConfirm from '@/components/modals/ModalConfirm.vue';
    import ModalAddEditTool from '@/components/modals/tools/ModalAddEditTool.vue'
    import ModalToolTransfer from '@/components/modals/tools/ModalToolTransfer.vue'
    import { Modal } from 'bootstrap'
    import ToolsWarehouseButtons from '@/components/tools/ToolsWarehouseButtons.vue';
    import ToolsCMD from '@/components/grid-templates/ToolsCMD.vue';
    import ModalToolQRScanner from '@/components/modals/tools/ModalToolQRScanner.vue';


    export default {
        components: {
            ToolTypesButtons,
            ModalConfirm,
            ModalAddEditTool,
            ToolsWarehouseButtons,
            ModalToolTransfer,
            ModalToolQRScanner,
        },
  
       data() {
     
            return {
                modalNewTool: null,
                modalNewToolTransfer: null,
                modalQR: null,
                confirmModal: null,

                allTools: [],

                toolType: undefined,
                warehouse: undefined,
                editTool: undefined,



                filterSettings: { type: "Excel" },
                pageSettings: { pageCount: 20},
                editSettings: { allowEditing: true, allowAdding: true, allowDeleting: true },
                toolbarAdmin: [{ text: 'Dodaj alat', tooltipText: 'Dodaj', prefixIcon: 'e-add', id: 'addTool'}, { text: 'Izmeni', tooltipText: 'Izmeni', prefixIcon: 'e-edit', id: 'editTool'}, { text: 'Obriši', tooltipText: 'Obriši', prefixIcon: 'e-delete', id: 'deleteTool'}],

                toolsImage: function() {
                    return {
                        template: {
                            extends: ToolsCMD,
                            propsData: {
                                column: "IMAGE"
                            },   
                        }
                    };
                },
                
                toolsNameTemplate: function() {
                    return {
                        template: {
                            extends: ToolsCMD,
                            propsData: {
                                column: "NAME"
                            },   
                        }
                    };
                },

                toolsCMD: function() {
                    return {
                        template: {
                            extends: ToolsCMD,
                            propsData: {
                                column: "CMD"
                            },
                            
                        }

                    };
                },
                
            }
        },

        provide: {
            grid: [Sort, Page, ColumnMenu, Filter, Group, Toolbar, Edit, Resize ]
        },

        computed: {
            ...mapState([
                'user',
            ]),

            toolbar() {
                if (this.user.role == "ADMIN") return this.toolbarAdmin;
                return null;
            },

            isManager() {
                if (this.user.role === "MANAGER" || this.user.role === "ADMIN" || this.userAssignment?.pwo?.pwo_role == 1)
                    return true;
                return false;
            },
        },


        

        methods: {
            
            formatDate: UtilService.formatDate,
            formatDateTime: UtilService.formatDateTime,
        
            fullnameAccessor(field, data) {
                let firstField = field.split('.')[0];
                let userData = data?.[firstField];
                if (!userData?.first_name) return;
                return userData.first_name + " " + userData.last_name; 
            },

            dateTimeAccessor(field, data) {
                return data[field] ? this.formatDateTime(data[field]) : '/';
            },
            // async loadAllTransports() {
            //     this.loading = true;
            //     this.error = null;

            //     await this.$store.dispatch('loadAllTransportVehicles', this.workOrderForm).catch((error) => {
            //         this.error = error;
            //     });

            //     this.loading = false;
            // },

            changedToolType() {
                this.loadTools();
            },

            changedWarehouse() {
                this.loadTools();
            },

            loadTools() {
                if (this.isManager)
                    this.loadAllTools();
                else
                    this.loadMyTools();
            },

            loadAllTools() {
                let query = {
                    type: this.toolType?.id,
                    warehouse: this.warehouse?.id
                }

                ToolsService.getAllTools(query).then(async (result)=>{
                    this.allTools = ToolsService.prepareToolsData(result.data);
                });
            },

            loadMyTools() {
                let query = {
                    worker: this.user.id,
                }

                ToolsService.getAllTools(query).then(async (result)=>{
                    this.allTools = ToolsService.prepareToolsData(result.data);
                });
            },

            async loadAllToolTypes() {
                await this.$store.dispatch('getAllToolTypes')
            },
            async loadAllWarehouses() {
                await this.$store.dispatch('getAllWarehouses')
            },

            loadData() {
                this.loadAllToolTypes();
                this.loadAllWarehouses();
                this.loadTools();
            },

            toolDBClick(event) {
                this.showEditToolModal(event.rowData);
            },

            showAddModal() {
                this.editTool = undefined;
                this.modalNewTool.show();
            },

            showEditToolModal(selected) {
                this.editTool = selected;
                this.modalNewTool.show();
            },

            showQRModal() {
                this.modalQR.show();
            },

            showToolTransferModal(selected) {
                this.editTool = selected;
                this.modalNewToolTransfer.show();
            },

            deleteSelectedTool() {
                let selected = (this.$refs.allTools.ej2Instances.getSelectedRecords());
                if (selected.length > 0) selected = selected[0];
                else return;

                let query = {
                    id: selected.id,
                }

                ToolsService.deleteTool(query).then(async ()=>{
                    this.loadTools();
                });
                
            },

            clickHandler(args) {

                if (args.item.id === "addTool"){
                    this.showAddModal();
                }

                if (args.item.id === "editTool"){
                    let selected = (this.$refs.allTools.ej2Instances.getSelectedRecords());
                    if (selected.length > 0) selected = selected[0];
                    else return;

                    this.showEditToolModal(selected);
                }

                if (args.item.id === "deleteTool"){
                    let selected = (this.$refs.allTools.ej2Instances.getSelectedRecords());
                    if (selected.length > 0) selected = selected[0];
                    else return;
                    
                    this.confirmModal.show();
                }
            },

            confirmedTool() {
                this.loadTools();
            },

            confirmedToolTransfer() {
                this.loadTools();
            },

        },


        created() {
        },

        mounted() {
            this.loadData();
            this.modalNewTool = new Modal(document.getElementById('modalNewTool'), {backdrop: 'static', keyboard: false});
            this.modalNewToolTransfer = new Modal(document.getElementById('modalNewToolTransfer'), {backdrop: 'static', keyboard: false});
            this.confirmModal = new Modal(document.getElementById('confirmModal'));
            this.modalQR = new Modal(document.getElementById('modalQR'));
        }


    }
</script>

<style scoped>



</style>
