<template>
    <div class="modal fade" :id="modalId" tabindex="-1" :aria-labelledby="modalId + 'Label'" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Grupe alata</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>

                <div class="modal-body">
                    
                    <div v-for="(warehouse, i) in oldWarehouses" :key="i" class="input-group">
                        <input :class="{'text-decoration-line-through': warehouse.deleted, 'fw-bold': warehouse.edited}" v-model="warehouse.name" @blur="changedType(warehouse)" type="text" class="form-control" aria-label="Dollar amount (with dot and two decimal places)">
                        <button class="btn btn-outline-secondary" type="button" @click="deleteToggle(warehouse)" ><font-awesome-icon icon="trash" /></button>
                    </div>

                    <div v-for="(warehouse, i) in newWarehouses" :key="'new-' + i" class="input-group">
                        <input v-model="warehouse.name" type="text" class="form-control fw-bold" aria-label="Dollar amount (with dot and two decimal places)">
                        <button class="btn btn-outline-secondary" type="button" @click="deleteNewWarehouse(i)" ><font-awesome-icon icon="trash" /></button>
                    </div>

                    <div class="d-grid">
                        <button class="btn btn-light mt-1 text-center" @click="addTypeRow"><font-awesome-icon icon="plus-circle" size="2x" /></button>
                    </div>

                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"> Zatvori </button>
                    <button @click="saveTypes()" type="button" class="btn btn-primary"> Sačuvaj </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import ToolsService from '@/service/ToolsService.js';
    import UtilService from '@/service/UtilService.js';

    // import QrcodeVue from 'qrcode.vue'

    export default {

        props: {
            modalId: String,
            warehouses: Array,
            thisModal: Object
        },

        components: {
        },
        
        data() {
            return {
                oldWarehouses: [],
                newWarehouses: [],
            }
        },

        computed: {

        },

        methods: {

            userFullName : UtilService.userFullName,

            validate() {
                let form = document.getElementById("tool-damage-form");
                form.classList.add('was-validated');

                if (!form.checkValidity())
                    return false;

                return true;
            },

            deleteToggle(warehouse) {
                if (warehouse.deleted) warehouse.deleted = false;
                else warehouse.deleted = true;

                this.$set(warehouse, 'edited', true);
            },

            deleteNewWarehouse(newTypeIndex) {
                this.newWarehouses.splice(newTypeIndex, 1);
            },

            addTypeRow() {
                let warehouse = {
                    name: "",
                    edited: true
                }

                this.newWarehouses.push(warehouse)
            },

            changedType(warehouse){
                // console.log(warehouse);
                this.$set(warehouse, 'edited', true);
            },

            saveTypes() {
                this.saveEditedTypes();
                this.uploadnewWarehouses();
            },

            saveEditedTypes() {
                let data = this.oldWarehouses.filter(x => x.edited != null);
                data.forEach(element => {
                    ToolsService.putWarehouse(element).then(result => {
                        console.log(result);
                    }).catch(error => {
                        console.log(error);
                        alert("Neuspesno cuvanje grupe alata")
                    }) 
                });
            },

            uploadnewWarehouses() {
                let data = this.newWarehouses.filter(x => x.name != "");
                data.forEach(element => {
                    ToolsService.addWarehouse(element).then(result => {
                        console.log(result);
                    }).catch(error => {
                        console.log(error);
                        alert("Neuspesno cuvanje grupe alata")
                    }) 
                });
            },

            initData() {
                this.oldWarehouses = UtilService.deepClone(this.warehouses);
                this.newWarehouses;
            },

            setUpListener() {
                let myModalEl = document.getElementById(this.modalId)
                myModalEl.addEventListener('shown.bs.modal', () => {
                    this.initData();
                })
            },

        },

        mounted() {
            this.setUpListener();
        },

        created() {
        }

    }
</script>

<style scoped>
.v-select.form-control{
    height: 4.5em;
}

.tool-image-top {
    transition: all 0.25s ease;
    cursor: zoom-in;
    max-width: 100px;
    max-height: 500px;
    border-radius: 100%;
}

.arrow-down-cricle {
    padding: 15px 21px 12px;
    display: inline-block;
    border-radius: 100%;
}

.arrow-down-cricle-success {
    border: 1px solid green;
    background: #8cff00;
}

.text-area-floating {
    height: 8em;
}

input[type=checkbox] {
  display: none;
}

input[type=checkbox]:checked ~ label > .tool-image-top {
    cursor: zoom-out;
    max-width: 100%;
    border-radius: 0;

}


.tool-image-damage {
    max-width: 100px;
    max-height: 500px;
    border-radius: 100%;

    transition: all 0.25s ease;
    cursor: zoom-in;
}

input[type=checkbox]:checked ~ label > .tool-image-damage {
    cursor: zoom-out;
    max-width: 400px;
    border-radius: 0;

}
</style>
