<template>
    <div>
        <!-- <modal-add-edit-tool-type :thisModal="modalNewToolType" modalId="modalNewToolType" title="Novi tool type" @confirmed="confirmed" /> -->
        <modal-edit-tool-types :thisModal="modalEditToolType" :toolTypes="toolTypes" modalId="modalEditToolType" @confirmed="confirmed" />

        <div class="m-0 d-flex flex-wrap" role="group" aria-label="Basic radio toggle button group">
            <div class="type-select">
                <input v-model="selectedToolTypeComputed" :value="undefined" type="radio" class="btn-check" name="btnradio" id="btn-all-types" autocomplete="off" checked @change="changedToolType">
                <label class="btn btn-outline-primary btn-sm type-select-lable" for="btn-all-types">Svi tipovi</label>
            </div>

            <template v-for="toolType in toolTypes">
                <div class="type-select" :key="toolType.id">
                    <input v-model="selectedToolTypeComputed" :value="toolType" type="radio" class="btn-check" name="btnradio" :id="'tool-type-' + toolType.id" autocomplete="off" @change="changedToolType">
                    <label class="btn btn-outline-primary btn-sm type-select-lable" :for="'tool-type-' + toolType.id">{{toolType.name}}</label>
                </div>
            </template>


            <div v-if="isAdmin" class=" justify-content-end type-select">
                <button v-if="toolTypes" class="btn btn-outline-primary btn-sm type-select-lable" @click="openEditModal()"><font-awesome-icon icon="pen" /></button>
                <button v-else class="btn btn-outline-primary btn-sm type-select-lable" @click="openEditModal()"><font-awesome-icon icon="plus" /> Dodaj grupu alata</button>
            </div>

        </div>
    </div>

</template>

<script>
    import {mapState} from 'vuex'
    // import ModalAddEditToolType from '@/components/modals/tools/ModalAddEditToolType.vue'
    import { Modal } from 'bootstrap';
    import ModalEditToolTypes from '@/components/modals/tools/ModalEditToolTypes.vue';

    export default {
        components: { ModalEditToolTypes },
        
        props: {
            selectedToolType: Object
        },

        data() {
            return {
                modalNewToolType: null,
                modalEditToolType: null,
            }
        },

        computed: {
            ...mapState([
                'toolTypes',
                'user',
            ]),

            selectedToolTypeComputed: {
                get () {return this.prop},
                set (value) { this.$emit('update:selectedToolType', value)}
            },

            isAdmin() {
                if (this.user.role == "ADMIN") return true;
                return false;
            }

        },

        methods: {
            changedToolType() {
                this.$emit('changed');
            },

            openModal() {
                console.log(Modal);
                console.log("novi");
                this.modalNewToolType.show();
            },

            openEditModal() {
                this.modalEditToolType.show();
            },

            confirmed() {
                this.$store.dispatch('getAllToolTypes');
            }
        },


        mounted() {
            this.modalNewToolType = new Modal(document.getElementById('modalNewToolType'), {backdrop: 'static', keyboard: false});
            this.modalEditToolType = new Modal(document.getElementById('modalEditToolType'));
        },

    }
</script>

<style>
    .type-select{
        padding: 0;
    }
    .type-select-lable {
        width: 100%;
        padding: 1em;
    }
</style>